<script>
import Vue from 'vue';
import '@voerro/vue-tagsinput/dist/style.css';
import Gen from '../helper/Gen';

Vue.component("vue-tags-input", ()=>import('@voerro/vue-tagsinput'))

export default {
  props:['value','name','suggestUrl','attr','placeholder'],
  data(){
    return {
      val: [],
      query: "",
      autocomplete: [],
      ajax: null,
    }
  },
  mounted(){
    if(this.value) this.val = this.value.split(",").map(x=>{ return {key:'',value:x} })
  },
  methods:{
    keyUp(e,v){
      this.query = e.target.value
      if(this.suggestUrl) Gen.delay(()=>{
        if(this.ajax) this.ajax.abort()
        this.ajax = Gen.apirest(this.suggestUrl, {query:this.query}, (err, resp)=>{
          console.log(err)
          if(err) return;
          this.$refs.tags.searchResults = resp.map(v=>{ return {key:v.value, value:v.text} })
        })
      }, 50)
    }
  },
  watch:{
    value(v){
      if(!v) return this.val = []
      this.val = v.split(",").map(x=>{ return {key:'',value:x} })
    },
    val(v){
      this.$emit('input', v.map(x=>{ return x.value }).join(','))
    },
  }
}
</script>

<style lang="scss">
.tags-input-badge{
  span{
    display: inline-block;
    font-size: 13px;
    padding: 3px 5px;
    font-weight: normal;
  }
}
.tags-input-remove{
  width: 10px;
  height: 10px;
  &:before,&:after{   
    background: #fd6666;
  }
}
</style>

<template>
  <div>
    <vue-tags-input
      ref="tags"
      v-model="val"
      @keyup="keyUp"
      :typeahead="true"
      typeahead-style="dropdown"
      :placeholder="placeholder"
    />
    <input :name="name" type="hidden" :value="value" v-bind="attr">
  </div>
</template>